export default class User {
    constructor(data) {
        if (data === undefined || data === null) {
            data = {};
        }
        this.id = data.id ?? null;
        this.email = data.email ?? '';
        this.name = data.name ?? '';
        this.password = null;
        this.isAdmin = data.isAdmin ?? false;
        if (data.subscriptionActiveUntil) {
            this.subscriptionActiveUntil = new Date(data.subscriptionActiveUntil);
        } else {
            this.subscriptionActiveUntil = null;
        }
        this.maximumImportRecordCount = data.maximumImportRecordCount ?? 0;
        this.hasDoctorsImported = data.hasDoctorsImported ?? false;
    }

    static collection(data) {
        const collection = [];
        for (let i = 0; i < data.length; i++) {
            collection.push(new User(data[i]));
        }
        return collection;
    }

    hasActiveSubscription() {
        return this.subscriptionActiveUntil !== null
            && this.subscriptionActiveUntil > new Date();
    }
}
