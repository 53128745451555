<template>
    <div>
        <b-button variant="light" @click="$router.back()">{{ $t(abortLabel) }}</b-button>
        <b-button variant="secondary" type="submit">{{ $t(submitLabel) }}</b-button>
    </div>
</template>

<script>
export default {
    name: "SubmitPanel",
    props: {
        submitLabel: {
            type: String,
            default: 'forms.submit',
        },
        abortLabel: {
            type: String,
            default: 'forms.abort',
        },
    },
}
</script>

<style scoped>
.btn {
    margin-right: .5em;
}
</style>
