<template>
    <b-form-group :label="$t('forms.inputs.practitioner-type-chooser.label')">
        <b-form-radio v-if="nullable" v-model="selected" name="practitioner-type-radio" :value="null">
            {{ $t('forms.inputs.practitioner-type-chooser.options.all') }}
        </b-form-radio>

        <b-form-radio v-model="selected" name="practitioner-type-radio" value="GP">
            {{ $t('forms.inputs.practitioner-type-chooser.options.GP') }}
        </b-form-radio>

        <b-form-radio v-model="selected" name="practitioner-type-radio" value="hospital">
            {{ $t('forms.inputs.practitioner-type-chooser.options.hospital') }}
        </b-form-radio>
    </b-form-group>
</template>

<script>
export default {
    name: "PractitionerTypeChooser",
    props: {
        nullable: {
            type: Boolean,
            default: true,
        },
        value: String,
    },
    data() {
        return {
            selected: this.value,
        };
    },
    watch: {
        selected(value) {
            this.$emit('input', value);
        },
    },
}
</script>
