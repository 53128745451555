<template>
    <b-container>
        <h1>LinkMed Admin</h1>
        <p>Willkommen, Administrator.</p>
    </b-container>
</template>

<script>
export default {
    props: {
        user: Object,
    },
}
</script>
