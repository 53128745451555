import Vue from 'vue'
import Vuex from 'vuex'
import {
    API_ERROR,
    API_SUCCESS,
    API_WAIT,
    ERROR,
    PURGE,
    SET_CANTONS,
    SET_LOCALITIES,
    SET_POSTAL_CODES,
    SET_SPECIALIZATIONS,
    SET_TRANSACTIONS,
    SET_USER,
    UPDATE_TRANSACTION
} from "@/store/mutation-types";
import Transaction from "@/models/Transaction";
import User from "@/models/User";
import router from "@/router";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        autoLogout: false,
        authCheckDone: false,
        cantons: null,
        localities: null,
        postalCodes: null,
        specializations: null,
        transactions: {},
        transactionsLoaded: false,
        pendingRequests: 0,
        error: false,
    },
    getters: {
        loading(state) {
            return state.pendingRequests > 0;
        },
        user(state) {
            return state.user;
        },
        transactionList(state) {
            const transactions = [];
            for (let t in state.transactions) {
                transactions.push(state.transactions[t]);
            }
            return Transaction.collection(transactions);
        }
    },
    mutations: {
        [PURGE](store) {
            store.user = null;
            store.transactions = {};
            store.transactionsLoaded = false;
        },

        [SET_USER](store, user) {
            store.user = user;
            store.autoLogout = false;
        },
        [SET_CANTONS](store, cantons) {
            store.cantons = cantons;
        },
        [SET_LOCALITIES](store, localities) {
            store.localities = localities;
        },
        [SET_POSTAL_CODES](store, postalCodes) {
            store.postalCodes = postalCodes;
        },
        [SET_SPECIALIZATIONS](store, specializations) {
            store.specializations = specializations;
        },
        [SET_TRANSACTIONS](store, transactions) {
            const transactionsObj = {};
            for (let i = 0; i < transactions.length; i++) {
                transactionsObj[transactions[i].id] = transactions[i];
            }
            store.transactions = transactionsObj;
            store.transactionsLoaded = true;
        },

        [API_WAIT](store) {
            store.pendingRequests++;
        },
        [API_SUCCESS](store) {
            store.pendingRequests--;
        },
        [API_ERROR](store, err) {
            if (err.response.status === 401) { // We've been logged out in the meantime
                router.push({name: 'EntryPage'});
                store.autoLogout = true;
                this.commit(PURGE);
            } else {
                store.error = true;
            }
            store.pendingRequests--;
        },
        [ERROR](store) {
            store.error = true;
        },

        [UPDATE_TRANSACTION](store, transaction) {
            if (transaction.id === undefined) {
                throw "Invalid Transaction";
            }
            store.transactions = Object.assign({}, store.transactions, {[transaction.id]: transaction});
        },
    },
    actions: {
        async checkAuth({state, commit}) {
            if (state.authCheckDone) return;
            state.authCheckDone = true;

            commit(API_WAIT);
            await Vue.axios.get('/api/auth/who')
                .then((res) => {
                    if (res.data.isLoggedIn) {
                        commit(SET_USER, new User(res.data.data));
                    }
                    commit(API_SUCCESS);
                }).catch((err) => commit(API_ERROR, err));
        },

        async loadCantons({state, commit}) {
            if (state.cantons !== null) return;
            commit(SET_CANTONS, []);

            commit(API_WAIT);
            await Vue.axios.get('/api/cantons')
                .then(data => {
                    commit(SET_CANTONS, data.data.data);
                    commit(API_SUCCESS);
                })
                .catch((err) => commit(API_ERROR, err));
        },

        async loadLocalities({state, commit}) {
            if (state.localities !== null) return;
            commit(SET_LOCALITIES, []);

            commit(API_WAIT);
            await Vue.axios.get('/api/localities')
                .then(data => {
                    commit(SET_LOCALITIES, data.data.data);
                    commit(API_SUCCESS);
                })
                .catch((err) => commit(API_ERROR, err));
        },

        async loadPostalCodes({state, commit}) {
            if (state.postalCodes !== null) return;
            commit(SET_POSTAL_CODES, []);

            commit(API_WAIT);
            await Vue.axios.get('/api/postal-codes')
                .then(data => {
                    commit(SET_POSTAL_CODES, data.data.data);
                    commit(API_SUCCESS);
                })
                .catch((err) => commit(API_ERROR, err));
        },

        async loadSpecializations({state, commit}) {
            if (state.specializations !== null) return;
            commit(SET_SPECIALIZATIONS, []);

            commit(API_WAIT);
            await Vue.axios.get('/api/specializations')
                .then(data => {
                    commit(SET_SPECIALIZATIONS, data.data.data);
                    commit(API_SUCCESS);
                })
                .catch((err) => commit(API_ERROR, err));
        },

        async loadTransaction({state, commit}, id) {
            if (state.transactions[id] !== undefined) return;

            commit(API_WAIT);
            await Vue.axios.get('/api/customer/transactions/' + id)
                .then(data => {
                    commit(UPDATE_TRANSACTION, data.data.data);
                    commit(API_SUCCESS);
                })
                .catch(() => {
                    commit(API_ERROR)
                });
        },

        async loadTransactions({state, commit}) {
            if (state.transactionsLoaded) return;

            commit(API_WAIT);
            await Vue.axios.get('/api/customer/transactions')
                .then(data => {
                    commit(SET_TRANSACTIONS, data.data.data);
                    commit(API_SUCCESS);
                })
                .catch((err) => commit(API_ERROR, err));
        },

        async loadAcquisition({state, dispatch, commit}, id) {
            await dispatch('loadTransaction', id);
            if (state.transactions[id].type !== 'acquisition') {
                commit(ERROR);
            }
        },

        async loadUpdate({state, dispatch, commit}, id) {
            await dispatch('loadTransaction', id);
            if (state.transactions[id].type !== 'update') {
                commit(ERROR);
            }
        },
    },
});
