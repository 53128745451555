<template>
    <b-container>
        <h1>{{ $t('customer.transactions.title') }}</h1>
        <computed-balance v-if="transactionList.length > 0" :transactions="transactionList"/>
        <transaction-list :transactions="transactionList" :user="user"/>
    </b-container>
</template>

<script>
import {mapGetters} from "vuex";
import TransactionList from "@/components/transactions/TransactionList";
import ComputedBalance from "@/components/transactions/ComputedBalance";

export default {
    name: "CustomerTransactions",
    components: {ComputedBalance, TransactionList},
    props: {
        user: Object,
    },
    computed: mapGetters(['transactionList']),
    created() {
        this.$store.dispatch('loadTransactions');
    },
}
</script>
