<template>
    <b-form @submit="submit($event)">
        <b-form-group :label="$t('admin.users.entity.name')">
            <error-display :errors="errors" name="name"/>
            <b-input :value="value.name" @input="input('name', $event)"
                     :placeholder="$t('admin.users.entity.name')" type="text"/>
        </b-form-group>

        <b-form-group :label="$t('admin.users.entity.email')">
            <error-display :errors="errors" name="email"/>
            <b-input :value="value.email"  @input="input('email', $event)"
                     :placeholder="$t('admin.users.entity.email')" type="email"/>
        </b-form-group>

        <b-form-group :label="$t('admin.users.entity.password')" :description="$t('admin.users.entity.password-hint')" v-if="showPassword">
            <error-display :errors="errors" name="password"/>
            <b-input :value="value.password"  @input="input('password', $event)"
                     :placeholder="$t('admin.users.entity.password')" type="password"/>
        </b-form-group>

        <b-form-checkbox :value="value.isAdmin"  @input="input('isAdmin', $event)"
                         class="mb-3" v-if="showAccountType">
            {{ $t('admin.users.entity.is-admin') }}
        </b-form-checkbox>

        <template v-if="!value.isAdmin">
            <hr>

            <b-form-group>
                <template #label>
                    {{ $t('admin.users.entity.subscription-active-until') }}
                    <span class="date-action">
                        (<a href="javascript:" @click="addYear()">{{ $t('admin.users.entity.subscription-active-until-add-year') }}</a>)
                    </span>
                </template>
                <error-display :errors="errors" name="subscriptionActiveUntil"/>
                <b-form-datepicker :value="value.subscriptionActiveUntil" @input="input('subscriptionActiveUntil', $event)"
                                   :placeholder="$t('admin.users.entity.subscription-active-until')"></b-form-datepicker>
            </b-form-group>

            <b-form-group :label="$t('admin.users.entity.maximum-import-record-count')">
                <error-display :errors="errors" name="maximumImportRecordCount"/>
                <b-input :value="value.maximumImportRecordCount"  @input="input('maximumImportRecordCount', $event)"
                         :placeholder="$t('admin.users.entity.maximum-import-record-count')" type="number"/>
            </b-form-group>
        </template>

        <slot></slot>
    </b-form>
</template>

<script>
import User from "@/models/User";
import ErrorDisplay from "@/components/ErrorDisplay";

export default {
    name: "UserForm",
    components: {ErrorDisplay},
    props: {
        value: User,
        showAccountType: Boolean,
        showPassword: Boolean,
        errors: Object,
    },
    methods: {
        submit(evt) {
            this.$emit('submit');
            evt.preventDefault();
        },
        addYear() {
            var date;
            if (this.value.subscriptionActiveUntil === null) {
                date = new Date();
            } else {
                date = new Date(this.value.subscriptionActiveUntil);
            }
            date.setFullYear(date.getFullYear() + 1);
            this.input('subscriptionActiveUntil', date);
        },
        input(prop, value) {
            this.$emit('input', {...this.value, [prop]: value});
        },
    },
}
</script>

<style scoped lang="scss">
.date-action {
    color: $gray-700;
    font-size: .8em;
}
</style>
