<template>
    <b-form @submit="submit($event)">
        <b-form-group :label="$t('admin.deposit.form.user')">
            <error-display :errors="errors" name="name"/>
            <b-input readonly :value="currentUser !== null ? currentUser.name : ''" type="text"/>
        </b-form-group>

        <b-form-group :label="$t('admin.deposit.form.booking-text')">
            <error-display :errors="errors" name="bookingText"/>
            <b-input :value="value.bookingText" @input="input('bookingText', $event)"
                     :placeholder="$t('admin.deposit.form.booking-text')" type="text"/>
        </b-form-group>

        <b-form-group :label="$t('admin.deposit.form.credits')">
            <error-display :errors="errors" name="credits"/>
            <b-input :value="value.credits" @input="input('credits', $event)"
                     :placeholder="$t('admin.deposit.form.credits')" type="number" min="0"/>
        </b-form-group>

        <slot></slot>
    </b-form>
</template>

<script>
import User from "@/models/User";
import ErrorDisplay from "@/components/ErrorDisplay";
import Transaction from "@/models/Transaction";

export default {
    name: "DepositForm",
    components: {ErrorDisplay},
    props: {
        value: Transaction,
        currentUser: User,
        errors: Object,
    },
    methods: {
        submit(evt) {
            this.$emit('submit');
            evt.preventDefault();
        },
        input(prop, value) {
            this.$emit('input', {...this.value, [prop]: value});
        },
    },
}
</script>
