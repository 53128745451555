<template>
    <div class="multi-select-container">
        <div v-if="itemsActive.length > 0" class="tag-list">
            <b-tag v-for="item in itemsActive" v-bind:key="item[primaryKey]"
                   @remove="toggle(item[primaryKey]);keepFocus()"
            >
                {{ item[label] }}
            </b-tag>
        </div>

        <form v-on:submit.prevent="toggle(itemsFiltered[0][primaryKey]);filter = ''">
            <b-input ref="filter" type="text" v-model="filter" autocomplete="off" class="filter-input"
                     @focus="receivedFocus" @blur="lostFocus"
            ></b-input>

            <label class="input-caret" :for="$refs.filter" @click="toggleFocus()">
                <b-icon icon="caret-down-fill"/>
            </label>
        </form>

        <div class="dropdown" v-bind:class="{'d-none': !showInactiveItems}">
            <b-list-group>
                <b-list-group-item v-for="item in itemsFiltered" v-bind:key="item[primaryKey]" variant="outline-primary"
                                   @click="toggle(item[primaryKey]);$refs.filter.focus()" href="javascript:"
                >
                    {{ item[label] }}
                </b-list-group-item>
            </b-list-group>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import {normalize} from "@/helpers/utils";

export default {
    name: "MultiSelect",
    props: {
        items: Array,
        label: String,
        primaryKey: String,
        value: Array,
        displayLimit: {
            type: Number,
            default: 200,
        },
    },
    data() {
        return {
            filter: '',
            checked: {},
            showInactiveItems: false,
            hasFocus: false,
        };
    },
    mounted() {
        for (let i = 0; i < this.value.length; i++) {
            this.toggle(this.value[i]);
        }
    },
    methods: {
        toggle(key) {
            if (this.checked[key] === undefined) {
                Vue.set(this.checked, key, true);
            } else {
                this.checked[key] = !this.checked[key];
            }
        },
        receivedFocus() {
            this.showInactiveItems = true;
            this.hasFocus = true
        },
        keepFocus() {
            if (this.showInactiveItems) {
                this.hasFocus = true;
                this.$refs.filter.focus();
            }
        },
        lostFocus() {
            this.hasFocus = false;
            setTimeout(() => {
                if (!this.hasFocus) {
                    this.showInactiveItems = false;
                }
            }, 200);
        },
        toggleFocus() {
            if (this.showInactiveItems) {
                this.hasFocus = false;
                this.showInactiveItems = false;
            } else {
                this.$refs.filter.focus();
            }
        },
    },
    computed: {
        itemsActive() {
            const items = [];
            for (let i = 0; i < this.items.length; i++) {
                if (this.checked[this.items[i][this.primaryKey]]) {
                    items.push(this.items[i]);
                }
            }
            return items;
        },
        itemsFiltered() {
            const items = [];
            let numDisplayedItems = 0;
            for (let i = 0; i < this.items.length; i++) {
                if (!this.checked[this.items[i][this.primaryKey]] &&
                    normalize(this.items[i][this.label]).match(normalize(this.filter))) {
                    items.push(this.items[i]);
                    numDisplayedItems++;
                    if (numDisplayedItems >= this.displayLimit) {
                        break;
                    }
                }
            }
            return items;
        },
        selected() {
            const selected = [];
            for (const i in this.checked) {
                if (this.checked[i]) {
                    selected.push(i);
                }
            }
            return selected;
        },
    },
    watch: {
        selected: {
            handler() {
                this.$emit('input', this.selected);
            },
            deep: true,
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";

.multi-select-container {
    position: relative;

    > form {
        position: relative;
    }
}

.tag-list {
    margin-bottom: .75rem;
}

.b-form-tag {
    margin-bottom: .25rem;
}

.input-caret {
    position: absolute;
    right: 0;
    padding: 0.375rem 0.75rem;
    margin: 1px;
    top: 0;
    line-height: 1.5;
    font-size: 1rem;
    cursor: pointer;
    color: $primary;

    &:hover {
        color: $secondary;
    }
}

:focus + .input-caret {
    color: $secondary;
}

.dropdown {
    position: absolute;
    z-index: 100;
    max-height: 250px;
    width: 100%;
    overflow: auto;
    border: $border-color $border-width solid;
    border-top: none;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    > .list-group {
        border-radius: 0;

        > .list-group-item {
            border-top: none;
            border-left: none;

            &:last-child {
                border-bottom: none;
            }
        }
    }
}
</style>
