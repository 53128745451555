<template>
    <b-container>
        <h1>{{ $t('user.settings.title') }}</h1>

        <h2>{{ $t('user.settings.change-password.title') }}</h2>
        <change-password-form/>
    </b-container>
</template>

<script>
import ChangePasswordForm from "@/components/forms/ChangePasswordForm";

export default {
    components: {ChangePasswordForm},
}
</script>
