<template>
    <b-popover v-if="!user.hasActiveSubscription() && !user.isAdmin" :target="target" triggers="hover" placement="top">
        <template #title>{{ $t('customer.popovers.subscription-inactive.' + translationType +'.title') }}</template>
        {{ $t('customer.popovers.subscription-inactive.' + translationType +'.message') }}
        <br><a :href="'mailto:' + $t('app.email.admin')"><b-icon-envelope class="mr-1"></b-icon-envelope>{{ $t('app.email.admin') }}</a>
    </b-popover>
</template>

<script>
export default {
    name: "SubscriptionInactivePopover",
    props: {
        user: Object,
        target: String,
    },
    computed: {
        translationType() {
            return this.user.subscriptionActiveUntil === null ? 'no-subscription' : 'expired';
        },
    },
}
</script>
