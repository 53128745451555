<template>
    <b-container v-if="offer !== null">
        <h1>{{ $t('customer.update.title') }}</h1>

        <p>{{ $t('customer.update.instructions') }}</p>

        <b-alert v-if="offer.numUpdates === 0" variant="success" :show="true">
            {{ $t('customer.update.no-update') }}
        </b-alert>
        <template v-else>
            <b-alert variant="info" :show="true">
                {{ $tc('customer.update.update-available', offer.numUpdates) }}
            </b-alert>

            <b-form-group :label="$t('customer.update.note')">
                <b-input v-model="note"/>
            </b-form-group>

            <b-button @click="update()">{{ $t('customer.update.action.update') }}</b-button>
        </template>
    </b-container>
</template>

<script>
import {UPDATE_TRANSACTION} from "@/store/mutation-types";

export default {
    props: {
        user: Object,
    },
    data: function () {
        return {
            note: null,
            offer: null,
        };
    },
    created() {
        this.$axios.get('/api/customer/update/offer')
            .then((res) => {
                this.offer = res.data;
            });
    },
    methods: {
        update() {
            this.$axios.post('/api/customer/update', {note: this.note})
                .then((res) => {
                    this.$store.commit(UPDATE_TRANSACTION, res.data.data);
                    this.$router.push('/update/' + res.data.data.id);
                });
        },
    },
}
</script>
