<template>
    <div>
        <b-alert show variant="success">
            <b-icon icon="check2"/>
            {{ $t('customer.import.success.message', {num: importResult.numImported}) }}
        </b-alert>

        <subscribed-download-button :user="user" :href="importResult.downloadUrl" variant="primary" class="mr-2"
                                    :label="$t('customer.import.success.download')"
                                    v-if="importResult.numImported > 0"
        />
        <b-btn class="mr-2" variant="primary" :href="importResult.inactiveDoctorsUrl"
               v-if="importResult.numInactive > 0"
        >
            {{ $t('customer.import.success.download-inactive-doctors') }}
        </b-btn>
        <b-btn class="mr-2" variant="primary" :href="importResult.duplicatesUrl" v-if="importResult.numDuplicates > 0">
            {{ $t('customer.import.success.download-duplicates') }}
        </b-btn>
    </div>
</template>

<script>
import SubscribedDownloadButton from "@/components/conditionals/SubscribedDownloadButton.vue";

export default {
    name: "SubsequentImportSuccessPanel",
    components: {SubscribedDownloadButton},
    props: {
        user: {
            type: Object
        },
        importResult: {
            type: Object,
        },
    },
}
</script>
