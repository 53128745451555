<template>
    <b-form @submit.prevent="changePassword($event)">
        <b-alert variant="success" :show="success">{{ $t('user.settings.change-password.form.success') }}</b-alert>
        <b-form-group :label="$t('user.settings.change-password.form.current')">
            <error-display :errors="errors" name="current"/>
            <b-input v-model="current" :placeholder="$t('user.settings.change-password.form.current')" type="password"/>
        </b-form-group>

        <b-form-group :label="$t('user.settings.change-password.form.new')">
            <error-display :errors="errors" name="new"/>
            <b-input v-model="newp" @input="checkPasswords" :placeholder="$t('user.settings.change-password.form.new')"
                     type="password"/>
        </b-form-group>

        <b-form-group>
            <template #label>
                {{ $t('user.settings.change-password.form.confirm') }}
                <b-icon v-if="typing" icon="three-dots" variant="dark"/>
                <b-icon v-if="match" icon="check-circle" variant="success"/>
                <b-icon v-if="mismatch" icon="x-circle" variant="danger"/>
            </template>
            <b-input v-model="confirm" @input="checkPasswords"
                     :placeholder="$t('user.settings.change-password.form.confirm')" type="password"/>
        </b-form-group>

        <b-button variant="secondary" type="submit" :disabled="!newp || newp !== confirm">
            {{ $t('user.settings.change-password.form.submit') }}
        </b-button>
    </b-form>
</template>

<script>
import ErrorDisplay from "@/components/ErrorDisplay";
import {defaultErrorHandler} from "@/helpers/utils";

let timeoutId = 0;

export default {
    name: "ChangePasswordForm",
    components: {ErrorDisplay},
    data() {
        return {
            current: "",
            newp: "",
            confirm: "",
            errors: {},
            typing: false,
            mismatch: false,
            match: false,
            success: false,
        };
    },
    methods: {
        changePassword() {
            this.$axios.patch('/api/user/password', {
                current: this.current,
                'new': this.newp,
            })
                .then(() => {
                    this.success = true;
                    this.errors = {};
                })
                .catch((arg) => {
                    this.success = false;
                    defaultErrorHandler(this)(arg);
                });
        },
        checkPasswords() {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            if (this.newp && this.confirm) {
                this.typing = true;
            }
            this.mismatch = false;
            this.match = false;
            timeoutId = setTimeout(this.comparePasswords, 200);
        },
        comparePasswords() {
            this.typing = false;
            if (!this.newp || !this.confirm) return;
            if (this.newp === this.confirm) {
                this.match = true;
            } else {
                this.mismatch = true;
            }
        },
    },
}
</script>
