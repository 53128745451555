export default class Transaction {
    constructor(data) {
        if (data === undefined || data === null) {
            data = {};
        }
        this.id = data.id ?? null;
        this.bookingText = data.bookingText ?? '';
        this.credits = data.credits ?? 0;
        this.type = data.type ?? null;
        this.glnCount = data.glnCount ?? 0;
        this.inactiveGlnCount = data.inactiveGlnCount ?? 0;
        this.downloadUrl = data.downloadUrl ?? null;
        this.downloadInactiveUrl = data.downloadInactiveUrl ?? null;
        if (data.date) {
            this.date = new Date(data.date);
        } else {
            this.date = null;
        }
        if ('specializations' in data) {
            this.specializations = data.specializations ?? null;
        }
        if ('nameGln' in data) {
            this.nameGln = data.nameGln ?? null;
        }
        if ('localities' in data) {
            this.localities = data.localities ?? null;
        }
        if ('postalCodes' in data) {
            this.postalCodes = data.postalCodes ?? null;
        }
        if ('cantons' in data) {
            this.cantons = data.cantons ?? null;
        }
        if ('practitionerType' in data) {
            this.practitionerType = data.practitionerType ?? null;
        }
        if ('user' in data) {
            this.user = data.user;
        }
    }

    static collection(data) {
        const collection = [];
        for (let i = 0; i < data.length; i++) {
            collection.push(new Transaction(data[i]));
        }
        return collection;
    }
}
