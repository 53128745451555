<template>
    <b-container>
        <h1>{{ $t('admin.edit-user.title') }}</h1>
        <b-alert :show="error || loadingError" variant="danger">{{ $t('errors.unknown-error') }}</b-alert>
        <user-form v-if="!loading && !loadingError" v-model="entity" :errors="errors" @submit="createUser()"
                   show-password
        >
            <submit-panel/>
        </user-form>
        <loading-indicator :value="loading"/>
    </b-container>
</template>

<script>
import User from "@/models/User";
import UserForm from "@/components/forms/UserForm";
import LoadingIndicator from "@/components/LoadingIndicator";
import SubmitPanel from "@/components/SubmitPanel";
import {defaultErrorHandler} from "@/helpers/utils";

export default {
    components: {SubmitPanel, LoadingIndicator, UserForm},
    props: {
        user: User,
    },
    data() {
        return {
            loading: true,
            loadingError: false,
            entity: null,
            errors: {},
            error: false,
        };
    },
    created() {
        this.$axios.get('/api/admin/users/' + this.$route.params.user)
            .then((response) => this.entity = new User(response.data.data))
            .catch(() => this.loadingError = true)
            .then(() => this.loading = false);
    },
    methods: {
        createUser() {
            this.$axios.patch('/api/admin/users/' + this.entity.id, this.entity)
                .then(() => this.$router.push({name: 'AdminUsers'}))
                .catch(defaultErrorHandler(this));
        },
    },
}
</script>
