<template>
    <div id="app">
        <nav-bar v-if="user !== null" class="mb-5" :user="user"/>
        <loading-indicator v-model="loading"/>
        <router-view v-bind:class="{'d-none': loading || error}" :user="user"/>
        <b-container v-if="error">
            <b-alert variant="danger" show>{{ $t('errors.unknown-error') }}</b-alert>
        </b-container>
    </div>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator";
import {mapGetters, mapState} from 'vuex';
import NavBar from "@/components/NavBar.vue";

export default {
    name: 'App',
    computed: {
        ...mapState(['user', 'error']),
        ...mapGetters(['loading']),
    },
    components: {
        NavBar,
        LoadingIndicator,
    },
}
</script>
