<template>
    <b-container v-if="analysis !== null">
        <h1>{{ $t('admin.analysis.title') }}</h1>

        <b-alert show class="mt-5">
            {{ $tc('admin.analysis.gln-counts.unknown', analysis.glnCounts.unknown) }}
        </b-alert>
        <b-button v-if="analysis.glnCounts.unknown > 0" :href="analysis.urls.unknown">
            {{ $t('admin.analysis.action.export-unknown') }}
        </b-button>
    </b-container>
</template>

<script>
export default {
    data: function () {
        return {
            analysis: null,
        };
    },
    created() {
        this.$axios.get('/api/admin/analysis')
            .then((res) => {
                this.analysis = res.data;
            });
    },
}
</script>
