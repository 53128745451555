<template>
    <b-container v-if="this.transaction !== null">
        <h1>{{ $t('customer.acquisition-success.title') }}</h1>
        <b-alert variant="success" :show="true">
            {{ $tc('customer.acquisition-success.confirmation', this.transaction.glnCount) }}
        </b-alert>

        <b-btn variant="primary" :disabled="this.transaction.count === 0" :href="transaction.downloadUrl">
            {{ $tc('customer.acquisition-success.action.export', this.transaction.glnCount) }}
        </b-btn>

        <b-btn :to="{name: 'CustomerAcquisition'}" class="ml-2" variant="light">
            {{ $t('customer.acquisition-success.action.buy-more') }}
        </b-btn>
    </b-container>
</template>

<script>
export default {
    computed: {
        transaction() {
            return this.$store.state.transactions[this.$route.params.id] ?? null;
        },
    },
    created() {
        this.$store.dispatch('loadAcquisition', this.$route.params.id);
    },
}
</script>
