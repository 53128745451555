<template>
        <b-container>
            <h1>{{ $t('admin.create-user.title') }}</h1>
            <b-alert :show="error" variant="danger">{{ $t('errors.unknown-error') }}</b-alert>
            <user-form v-model="entity" :errors="errors" @submit="createUser()" show-account-type show-password>
                <submit-panel :submit-label="$t('forms.create')"/>
            </user-form>
        </b-container>
</template>

<script>
import User from "@/models/User";
import UserForm from "@/components/forms/UserForm";
import SubmitPanel from "@/components/SubmitPanel";
import {defaultErrorHandler} from "@/helpers/utils";

export default {
    components: {SubmitPanel, UserForm},
    props: {
        user: User,
    },
    data() {
        return {
            entity: new User(),
            errors: {},
            error: false,
        };
    },
    methods: {
        createUser() {
            this.$axios.post('/api/admin/users', this.entity)
                .then(() => this.$router.push({name: 'AdminUsers'}))
                .catch(defaultErrorHandler(this));
        },
    },
}
</script>
