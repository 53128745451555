<template>
    <b-container v-if="this.transaction !== null">
        <h1>{{ $t('customer.update-success.title') }}</h1>
        <b-alert variant="success" :show="true">
            {{ $tc('customer.update-success.confirmation', this.transaction.glnCount) }}
        </b-alert>

        <b-btn variant="primary" :disabled="this.transaction.glnCount === 0" :href="transaction.downloadUrl">
            {{ $tc('customer.update-success.action.export', this.transaction.glnCount) }}
        </b-btn>

        <template v-if="this.transaction.inactiveGlnCount !== 0">
            <b-alert variant="info" :show="true" class="mt-4">
                {{ $tc('customer.update-success.inactive', this.transaction.inactiveGlnCount) }}
            </b-alert>

            <b-btn variant="primary" :disabled="this.transaction.inactiveGlnCount === 0" :href="transaction.downloadInactiveUrl">
                {{ $tc('customer.update-success.action.export-inactive', this.transaction.inactiveGlnCount) }}
            </b-btn>
        </template>
    </b-container>
</template>

<script>
export default {
    computed: {
        transaction() {
            return this.$store.state.transactions[this.$route.params.id] ?? null;
        },
    },
    created() {
        this.$store.dispatch('loadUpdate', this.$route.params.id);
    },
}
</script>
