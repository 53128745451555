import diacriticsMap from "@/helpers/diacritics";
import qs from "qs";

export function dateFormatter(value) {
    if (value === null) {
        return '-';
    }
    const formatter = Intl.DateTimeFormat('de-ch', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    return formatter.format(value);
}

export function dateTimeFormatter(date) {
    if (date === null) {
        return '-';
    }
    const formatter = Intl.DateTimeFormat('de-ch', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });
    return formatter.format(date);
}

export function defaultErrorHandler(vm) {
    return (error) => {
        if (error.response.status === 422) {
            vm.errors = error.response.data.errors;
        } else {
            vm.error = true;
        }
    };
}

export function normalize(string) {
    return removeDiacritics(string.toLowerCase()).replace(/[^a-z0-9]/g, '');
}

function removeDiacritics(string) {
    for (let i = 0; i < diacriticsMap.length; i++) {
        string = string.replace(diacriticsMap[i].letters, diacriticsMap[i].base);
    }
    return string;
}

export function navigate(path, params) {
    const joinedParams = qs.stringify(params);
    const search = (joinedParams.length > 0 ? '?' + joinedParams : '');
    if (path[0] !== '/' || location.port !== "8080") {
        location.href = path + search;
    } else { // development mode, replace port 8080 by 8000 to prevent vue server to intercept the request
        location.href = location.protocol + '//' + location.hostname + ':8000' + path + search;
    }
}
