<template>
    <chiron-dialog :title="$t('app.welcome')">
        <form @submit="login">
            <b-alert v-model="autoLogout" variant="warning" dismissible>
                {{ $t('auth.login.auto-logout')}}
            </b-alert>
            <b-alert v-model="error" variant="danger" dismissible>
                {{ $t('auth.login.error') }}
            </b-alert>
            <b-form-group>
                <b-input type="text" name="email" v-model="credentials.email" :placeholder="$t('auth.login.email')"/>
            </b-form-group>
            <b-form-group>
                <b-input type="password" name="password" v-model="credentials.password"
                         :placeholder="$t('auth.login.password')"
                />
            </b-form-group>
            <b-button type="submit" variant="primary">{{ $t('auth.login.submit') }}</b-button>
        </form>
    </chiron-dialog>
</template>

<script>
import ChironDialog from "@/components/ChironDialog";
import User from "@/models/User";
import {mapState} from "vuex";

export default {
    name: "LoginForm",
    components: {ChironDialog},
    props: {
        value: Object,
    },
    computed: mapState(['autoLogout']),
    data: function () {
        return {
            error: false,
            credentials: {
                email: '',
                password: '',
            }
        };
    },
    mounted() {
        this.$el.querySelector('[name="email"]').focus();
    },
    methods: {
        login: function (evt) {
            evt.preventDefault();
            this.$axios.post('/api/auth/login', this.credentials)
                .then((response) => {
                    const user = new User(response.data.data);
                    this.error = false;
                    this.$store.commit('SET_USER', user);
                    if (this.$router.currentRoute.name === 'EntryPage') {
                        if (this.$router.currentRoute.query.continue) {
                            this.$router.push(this.$router.currentRoute.query.continue);
                        } else if (user.isAdmin) {
                            this.$router.push({name: 'AdminDashboard'});
                        } else {
                            this.$router.push({name: 'CustomerDashboard'});
                        }
                    }
                })
                .catch(() => {
                    this.credentials.password = '';
                    this.error = true;
                });
        },
    },
}
</script>
