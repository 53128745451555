<template>
  <div class="container" v-bind:class="{'d-none': !value}">
    <b-icon icon="three-dots" animation="cylon" variant="primary"></b-icon>
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    value: Boolean,
  },
}
</script>

<style scoped>
.container {
  font-size: 4em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
