<template>
    <b-container>
        <h1>
            {{ $t('admin.user-transactions.title') }}: {{ currentUser !== null ? currentUser.name : '' }}
            <span class="float-right">
                    <router-link custom v-slot="{navigate}" :to="{name:'AdminUsers'}">
                        <a class="btn btn-light" @click="navigate" href="javascript:">
                            <b-icon icon="arrow-left-short"/>
                            {{ $t('admin.user-transactions.back-to-users') }}
                        </a>
                    </router-link>
                    <router-link custom v-slot="{navigate}"
                                 :to="{name:'AdminDeposit', params:{user:$route.params.user}}"
                    >
                        <a class="btn btn-secondary" @click="navigate" href="javascript:">
                            <b-icon icon="plus"/>
                            {{ $t('admin.deposit.title') }}
                        </a>
                    </router-link>
                </span>
        </h1>
        <transaction-list v-if="!loading" :transactions="transactions" :user="user"></transaction-list>
        <loading-indicator :value="loading"/>
    </b-container>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator";
import User from "@/models/User";
import Transaction from "@/models/Transaction";
import TransactionList from "@/components/transactions/TransactionList";

export default {
    components: {TransactionList, LoadingIndicator},
    props: {
        user: Object,
    },
    data() {
        return {
            loading: true,
            currentUser: null,
            transactions: [],
        };
    },
    created() {
        this.$axios.get('/api/admin/users/' + this.$route.params.user)
            .then((response) => this.currentUser = new User(response.data.data))
            .catch(/* don't care */);
        this.$axios.get('/api/admin/users/' + this.$route.params.user + '/transactions')
            .then((response) => {
                this.transactions = Transaction.collection(response.data.data);
                this.loading = false;
            });
    },
}
</script>

<style lang="scss" scoped>
h1 .btn {
    margin-left: .5em;
}
</style>
