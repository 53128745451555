<template>
    <b-form-group>
        <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0" @click="$refs.select.toggleFocus()">
            {{ $t('forms.inputs.specialization-chooser.label') }}
        </legend>
        <multi-select :items="specializations" primary-key="id" label="title" v-model="selectedSpecializations"
                      ref="select"
        ></multi-select>
    </b-form-group>
</template>

<script>
import MultiSelect from "@/components/inputs/MultiSelect";
import {mapState} from "vuex";

export default {
    name: "SpecializationChooser",
    components: {MultiSelect},
    props: {
        value: Array,
    },
    data() {
        return {
            selectedSpecializations: this.value,
        };
    },
    computed: mapState(['specializations']),
    created() {
        this.$store.dispatch('loadSpecializations');
    },
    watch: {
        selectedSpecializations() {
            this.$emit('input', this.selectedSpecializations);
        },
    },
}
</script>
