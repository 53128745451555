<template>
    <chiron-dialog :title="$t('errors.404.title')">
        <p>{{ $t('errors.404.message') }}</p>
    </chiron-dialog>
</template>

<script>
import ChironDialog from "@/components/ChironDialog";

export default {
    components: {ChironDialog}
}
</script>
