<template>
    <b-container v-if="analysis !== null">
        <h1>{{ $t('customer.analysis.title') }}</h1>

        <b-alert show class="mt-5">
            {{ $tc('customer.analysis.gln-counts.total', analysis.glnCounts.total) }}
        </b-alert>
        <subscribed-download-button :user="user" :label="$t('customer.analysis.action.export-database')"
                                    @click="navigate('/api/customer/export/download')"
        />

        <template v-if="analysis.glnCounts.inactive > 0">
            <b-alert show class="mt-5">
                {{ $tc('customer.analysis.gln-counts.inactive', analysis.glnCounts.inactive) }}
            </b-alert>
            <subscribed-download-button :user="user" :label="$t('customer.analysis.action.export-inactive')"
                                        :href="analysis.urls.inactive"
            />
        </template>

        <template v-if="analysis.glnCounts.institutions > 0">
            <b-alert show class="mt-5">
                {{ $tc('customer.analysis.gln-counts.institutions', analysis.glnCounts.institutions) }}
            </b-alert>
            <subscribed-download-button :user="user" :label="$t('customer.analysis.action.export-institutions')"
                                        :href="analysis.urls.institutions"
            />
        </template>

        <template v-if="analysis.glnCounts.unknown > 0">
            <b-alert show class="mt-5">
                {{ $tc('customer.analysis.gln-counts.unknown', analysis.glnCounts.unknown) }}
            </b-alert>
            <subscribed-download-button :user="user" :label="$t('customer.analysis.action.export-unknown')"
                                        :href="analysis.urls.unknown"
            />
        </template>
    </b-container>
</template>

<script>
import SubscribedDownloadButton from "@/components/conditionals/SubscribedDownloadButton";
import {navigate} from '@/helpers/utils';

export default {
    components: {SubscribedDownloadButton},
    props: {
        user: Object,
    },
    data: function () {
        return {
            analysis: null,
        };
    },
    created() {
        this.$axios.get('/api/customer/analysis')
            .then((res) => {
                this.analysis = res.data;
            });
    },
    methods: {
        navigate,
    },
}
</script>
