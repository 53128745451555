<template>
    <div>
        <template v-if="numTotalNewGlns === 0">
            <b-alert variant="info" show>
                <b-icon icon="info-circle"/>
                {{ $t('customer.import.analysis.no-new-glns') }}
            </b-alert>
            <a class="btn btn-primary" @click="$emit('reset')">
                {{ $t('customer.import.analysis.retry') }}
            </a>
        </template>
        <template v-else>
            <b-alert variant="success" show>
                <b-icon icon="check2"/>
                {{ $tc('customer.import.analysis.new-glns', analysisResult.numActiveDoctors) }}
            </b-alert>
            <b-alert show v-if="analysisResult.numInvalidRecords">
                <b-icon icon="info-circle"/>
                {{ $tc('customer.import.analysis.hints.non-glns', analysisResult.numInvalidRecords) }}<br>
            </b-alert>
            <b-alert show v-if="analysisResult.invalidGlns && analysisResult.invalidGlns.length > 0" variant="warning">
                <b-icon icon="info-circle"/>
                {{
                    $tc('customer.import.analysis.hints.invalid',
                        analysisResult.invalidGlns.length,
                        {gln: analysisResult.invalidGlns.join(', ')})
                }}<br>
            </b-alert>
            <b-alert show v-if="analysisResult.numDuplicates" variant="warning">
                <b-icon icon="info-circle"/>
                {{ $tc('customer.import.analysis.hints.duplicates', analysisResult.numDuplicates) }}
            </b-alert>
            <b-alert show v-if="analysisResult.numInactiveDoctors">
                <b-icon icon="info-circle"/>
                {{ $tc('customer.import.analysis.hints.before-import.inactive', analysisResult.numInactiveDoctors) }}<br>
            </b-alert>
            <b-alert show v-if="analysisResult.numInstitutions">
                <b-icon icon="info-circle"/>
                {{ $tc('customer.import.analysis.hints.institutions', analysisResult.numInstitutions) }}<br>
            </b-alert>
            <b-alert show v-if="analysisResult.numUnknown">
                <b-icon icon="info-circle"/>
                {{ $tc('customer.import.analysis.hints.before-import.unknown', analysisResult.numUnknown) }}<br>
            </b-alert>
            <b-alert :show="analysisResult.requiredCredits > analysisResult.availableCredits" variant="danger">
                {{ $t('customer.import.analysis.insufficient-funding', {credits: analysisResult.availableCredits}) }}
            </b-alert>
            <b-btn variant="primary" @click="$emit('import')"
                   :disabled="analysisResult.requiredCredits > analysisResult.availableCredits">
                {{ $t('customer.import.analysis.do-import', {credits: analysisResult.requiredCredits}) }}
            </b-btn>
        </template>
    </div>
</template>

<script>
export default {
    name: "AnalysisResult",
    props: {
        analysisResult: {
            type: Object,
        },
    },
    computed: {
        numTotalNewGlns() {
            if (this.analysisResult) {
                return this.analysisResult.numActiveDoctors +
                    this.analysisResult.numInactiveDoctors +
                    this.analysisResult.numInstitutions +
                    this.analysisResult.numUnknown;
            } else {
                return null;
            }
        }
    }
}
</script>
