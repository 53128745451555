<template>
    <div class="d-inline-block">
        <div class="d-inline-block" :id="id">
            <component :is="tag || 'b-button'"
                       :href="isDisabled ? 'javascript:' : href"
                       :disabled="isDisabled"
                       @click="isDisabled || $emit('click')"
                       :variant="variant"
            >
                {{ label }}
            </component>
        </div>

        <subscription-inactive-popover :user="user" :target="id"/>
    </div>
</template>

<script>
import SubscriptionInactivePopover from "@/components/popovers/SubscriptionInactivePopover";

export default {
    name: "SubscribedDownloadButton",
    components: {SubscriptionInactivePopover},
    props: {
        user: Object,
        href: String,
        label: String,
        disabled: Boolean,
        variant: String,
        tag: String,
    },
    computed: {
        id() {
            return this._uid + '-container';
        },
        isDisabled() {
            return this.disabled || (!this.user.hasActiveSubscription() && !this.user.isAdmin);
        },
    },
}
</script>
