import Vue from 'vue'
import store from '@/store/index';
import VueRouter from 'vue-router'
import CustomerDashboard from "@/views/customer/CustomerDashboard";
import AdminDashboard from "@/views/admin/AdminDashboard";
import NotFound from "@/views/NotFound";
import AdminTransactions from "@/views/admin/AdminTransactions";
import AdminUsers from "@/views/admin/AdminUsers";
import AdminCreateUser from "@/views/admin/AdminCreateUser";
import AdminEditUser from "@/views/admin/AdminEditUser";
import AdminUserTransactions from "@/views/admin/AdminUserTransactions";
import AdminDeposit from "@/views/admin/AdminDeposit";
import i18n from "@/i18n";
import UserSettings from "@/views/user/UserSettings";
import CustomerInitialImport from "@/views/customer/CustomerInitialImport";
import CustomerExport from "@/views/customer/CustomerExport";
import CustomerAcquisition from "@/views/customer/CustomerAcquisition";
import CustomerUpdate from "@/views/customer/CustomerUpdate";
import CustomerTransactions from "@/views/customer/CustomerTransactions";
import LoginForm from "@/views/LoginForm";
import CustomerAcquisitionSuccess from "@/views/customer/CustomerAcquisitionSuccess";
import CustomerUpdateSuccess from "@/views/customer/CustomerUpdateSuccess";
import CustomerAnalysis from "@/views/customer/CustomerAnalysis";
import AdminAnalysis from "@/views/admin/AdminAnalysis";
import CustomerSubsequentImport from "@/views/customer/CustomerSubsequentImport.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'EntryPage',
        component: LoginForm,
        meta: {
            title: 'customer.dashboard.title-bar',
        },
        beforeEnter(to, from, next) {
            if (store.state.user === null) {
                next();
            } else if (store.state.user.isAdmin) {
                next({name: 'AdminDashboard'});
            } else {
                next({name: 'CustomerDashboard'});
            }
        },
    },
    {
        path: '/dashboard',
        name: 'CustomerDashboard',
        component: CustomerDashboard,
        meta: {
            title: 'customer.dashboard.title-bar',
            customerOnly: true,
        },
    },
    {
        path: '/import',
        name: 'CustomerInitialImport',
        component: CustomerInitialImport,
        meta: {
            title: 'customer.dashboard.title-bar',
            customerOnly: true,
        },
        beforeEnter(to, from, next) {
            if (!store.state.user.hasDoctorsImported) {
                next();
            } else {
                next({name: 'CustomerDashboard'});
            }
        },
    },
    {
        path: '/query',
        name: 'CustomerExport',
        component: CustomerExport,
        meta: {
            title: 'customer.export.title-bar',
            customerOnly: true,
        },
    },
    {
        path: '/acquisition',
        name: 'CustomerAcquisition',
        component: CustomerAcquisition,
        meta: {
            title: 'customer.acquisition.title-bar',
            customerOnly: true,
        },
    },
    {
        path: '/acquisition/:id',
        name: 'CustomerAcquisitionSuccess',
        component: CustomerAcquisitionSuccess,
        meta: {
            title: 'customer.acquisition-success.title-bar',
            customerOnly: true,
        },
    },
    {
        path: '/import',
        name: 'CustomerSubsequentImport',
        component: CustomerSubsequentImport,
        meta: {
            title: 'customer.subsequent-import.title-bar',
            customerOnly: true,
        },
    },
    {
        path: '/update',
        name: 'CustomerUpdate',
        component: CustomerUpdate,
        meta: {
            title: 'customer.update.title-bar',
            customerOnly: true,
        },
    },
    {
        path: '/update/:id',
        name: 'CustomerUpdateSuccess',
        component: CustomerUpdateSuccess,
        meta: {
            title: 'customer.update-success.title-bar',
            customerOnly: true,
        },
    },
    {
        path: '/transactions',
        name: 'CustomerTransactions',
        component: CustomerTransactions,
        meta: {
            title: 'customer.transactions.title-bar',
            customerOnly: true,
        },
    },
    {
        path: '/analysis',
        name: 'CustomerAnalysis',
        component: CustomerAnalysis,
        meta: {
            title: 'customer.analysis.title-bar',
            customerOnly: true,
        },
    },
    {
        path: '/settings',
        name: 'UserSettings',
        component: UserSettings,
        meta: {
            title: 'user.settings.title-bar'
        }
    },
    {
        path: '/admin',
        name: 'AdminDashboard',
        component: AdminDashboard,
        meta: {
            title: 'admin.dashboard.title-bar',
        },
    },
    {
        path: '/admin/user',
        name: 'AdminUsers',
        component: AdminUsers,
        meta: {
            title: 'admin.users.title-bar',
        },
    },
    {
        path: '/admin/users/create',
        name: 'AdminCreateUser',
        component: AdminCreateUser,
        meta: {
            title: 'admin.create-user.title-bar',
        },
    },
    {
        path: '/admin/users/:user',
        name: 'AdminEditUser',
        component: AdminEditUser,
        meta: {
            title: 'admin.edit-user.title-bar',
        },
    },
    {
        path: '/admin/users/:user/transactions',
        name: 'AdminUserTransactions',
        component: AdminUserTransactions,
        meta: {
            title: 'admin.user-transactions.title-bar',
        },
    },
    {
        path: '/admin/users/:user/transactions/create',
        name: 'AdminDeposit',
        component: AdminDeposit,
        meta: {
            title: 'admin.deposit.title-bar',
        },
    },
    {
        path: '/admin/transactions',
        name: 'AdminTransactions',
        component: AdminTransactions,
        meta: {
            title: 'admin.transactions.title-bar',
        },
    },
    {
        path: '/admin/analysis',
        name: 'AdminAnalysis',
        component: AdminAnalysis,
        meta: {
            title: 'admin.analysis.title-bar',
        },
    },
    {
        path: '*',
        name: '404',
        component: NotFound,
        meta: {
            title: 'errors.404.title',
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (route, a, next) => {
    await store.dispatch('checkAuth');
    const user = store.state.user;

    let authorized;
    if (user === null) { // Guests can only access the login form
        authorized = route.name === 'EntryPage';
    } else if (user.isAdmin) { // Administrators have access to everything that is not "customerOnly"
        authorized = typeof route.meta.customerOnly === 'undefined' || !route.meta.customerOnly;
    } else { // Once a Customer has completed the initial data import,
        if (!user.hasDoctorsImported && route.name !== 'CustomerInitialImport') {
            next({name: 'CustomerInitialImport'});
            return;
        }
        // they have access to everything that does not start with /admin
        authorized = !route.path.startsWith('/admin');
    }

    if (authorized) {
        next();
    } else {
        next({name: 'EntryPage', query: {continue: route.fullPath}});
    }
});

router.beforeEach((to, a, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if (nearestWithTitle) document.title = i18n.t(nearestWithTitle.meta.title);
    next();
})

export default router
