<template>
    <b-form-group>
        <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0" @click="$refs.select.toggleFocus()">
            {{ $t('forms.inputs.region-chooser.label') }}
        </legend>
        <multi-select :items="regions" primary-key="key" label="label" v-model="selectedRegions" ref="select"
        ></multi-select>
    </b-form-group>
</template>

<script>
import MultiSelect from "@/components/inputs/MultiSelect";
import {mapState} from "vuex";

export default {
    name: "RegionChooser",
    components: {MultiSelect},
    props: {
        selectedCantons: Array,
        selectedLocalities: Array,
        selectedPostalCodes: Array,
    },
    data() {
        return {
            /**
             * Used to detect changes of `computedSelectedCantons`. Note, that there is no operation to replace selected
             * elements.
             */
            selectedCantonsCount: this.selectedCantons.length,
            /**
             * Used to detect changes of `computedSelectedLocalities`. Note, that there is no operation to replace
             * selected elements.
             */
            selectedLocalitiesCount: this.selectedLocalities.length,
            /**
             * Used to detect changes of `computedSelectedPostalCodes`. Note, that there is no operation to replace
             * selected elements.
             */
            selectedPostalCodesCount: this.selectedPostalCodes.length,
            selectedRegions: [
                ...this.selectedCantons,
                ...this.selectedLocalities,
                ...this.selectedPostalCodes,
            ],
        };
    },
    computed: {
        ...mapState(['cantons', 'localities', 'postalCodes']),
        regions() {
            return [
                ...this.cantons.map(c => ({
                    key: c.symbol,
                    label: this.$t('forms.inputs.region-chooser.canton', {canton: c.name})
                })),
                ...this.localities.map(l => ({key: l.name, label: l.name})),
                ...this.postalCodes.map(p => ({key: p.code, label: p.code})),
            ];
        },
        computedSelectedCantons() {
            return this.selectedRegions.filter(r => this.cantons.filter(c => c.symbol === r).length > 0);
        },
        computedSelectedLocalities() {
            return this.selectedRegions.filter(r => this.localities.filter(l => l.name === r).length > 0);
        },
        computedSelectedPostalCodes() {
            return this.selectedRegions.filter(r => this.postalCodes.filter(p => p.code === r).length > 0);
        },
    },
    created() {
        this.$store.dispatch('loadCantons');
        this.$store.dispatch('loadLocalities');
        this.$store.dispatch('loadPostalCodes');
    },
    watch: {
        computedSelectedCantons() {
            if (this.selectedCantonsCount !== this.computedSelectedCantons.length) {
                this.selectedCantonsCount = this.computedSelectedCantons.length;
                this.$emit('input:selectedCantons', this.computedSelectedCantons);
            }
        },
        computedSelectedLocalities() {
            if (this.selectedLocalitiesCount !== this.computedSelectedLocalities.length) {
                this.selectedLocalitiesCount = this.computedSelectedLocalities.length;
                this.$emit('input:selectedLocalities', this.computedSelectedLocalities);
            }
        },
        computedSelectedPostalCodes() {
            if (this.selectedPostalCodesCount !== this.computedSelectedPostalCodes.length) {
                this.selectedPostalCodesCount = this.computedSelectedPostalCodes.length;
                this.$emit('input:selectedPostalCodes', this.computedSelectedPostalCodes);
            }
        },
    },
}
</script>
