export const PURGE = 'PURGE';

export const SET_USER = 'SET_USER';
export const SET_CANTONS = 'SET_CANTONS';
export const SET_LOCALITIES = 'SET_LOCALITIES';
export const SET_POSTAL_CODES = 'SET_POSTAL_CODES';
export const SET_SPECIALIZATIONS = 'SET_SPECIALIZATIONS';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';

export const API_WAIT = 'API_WAIT';
export const API_SUCCESS = 'API_SUCCESS';
export const API_ERROR = 'API_ERROR';
export const ERROR = 'ERROR';

export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
