<template>
    <div class="file-area text-center" ref="dropZone" @dragover.prevent="dragover"
         @dragleave.prevent="dragleave"
         @drop.prevent="drop"
    >
        <input type="file" multiple name="file" id="fileHandle" @change="onChange" ref="file"
               :accept="supportedMimeTypes"
        />

        <label v-if="!file" for="fileHandle" class="p-5">
            {{ $t('customer.import.form.choose-file') }}<br/>
            <span class="hint">{{ $t('customer.import.form.supported-types') }}</span>
        </label>
        <div v-else class="file-display p-5">
            <p>
                <span class="file-type"><b-icon icon="file-spreadsheet" size="100"/></span>
                <br/>
                {{ file.name }}
            </p>
            <b-alert class="text-left" variant="danger" v-if="error" show>
                {{ errorMessage ? errorMessage : $t('customer.import.form.error') }}
                <a href="javascript:" @click="reset">{{ $t('customer.import.form.retry') }}</a>
            </b-alert>
            <template v-else-if="uploadProgress < 1">
                <p class="mb-2">{{ $t('customer.import.form.uploading') }}</p>
                <b-progress show-progress animated max="1" :value="uploadProgress" variant="secondary"></b-progress>
            </template>
            <template v-else>
                <p class="mb-0">
                    <b-spinner variant="primary" small/>
                    {{ $t('customer.import.form.processing') }}
                </p>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "ImportAcceptForm",
    props: {
        uploadProgress: {
            default: 0,
            type: Number,
        },
        error: {
            default: false,
            type: Boolean,
        },
        errorMessage: {
            default: null,
            type: String,
        },
    },

    data() {
        return {
            supportedMimeTypes: [
                'text/csv',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.oasis.opendocument.spreadsheet',
            ],
            file: null,
        };
    },

    methods: {
        onChange() {
            this.file = this.$refs.file.files[0];
            this.$emit('file', this.file);
        },
        dragover(event) {
            if (this.file) return;
            event.currentTarget.classList.add('highlight');
        },
        dragleave(event) {
            event.currentTarget.classList.remove('highlight');
        },
        drop(event) {
            if (this.file) return;
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange(); // Trigger the onChange event manually
            event.currentTarget.classList.remove('highlight');
        },
        reset() {
            this.file = null;
            this.$emit('reset');
        },
    },
}
</script>

<style scoped lang="scss">
[type="file"] {
    opacity: 0;
    position: absolute;
}

.file-area {
    border: 1px solid $secondary;

    &.highlight {
        border-color: $primary;
    }
}

label {
    display: block;
    cursor: pointer;
}

.hint {
    color: $dark;
    font-size: .8em;
}

.file-type {
    font-size: 2em;
}
</style>
