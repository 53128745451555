<template>
    <small class="root">
        <template v-if="specializations.length === 0 && locations.length === 0 && (nameGln === null || nameGln.length === 0)">
            {{ $t(translationKey('no-filter')) }}
        </template>
        <template v-else-if="specializations.length === 0">
            {{
                $tc(translationKey('no-specialization'), locations.length, {
                    locations: locationList,
                })
            }}
        </template>
        <template v-else-if="locations.length === 0">
            {{
                $tc(translationKey('no-location'), specializations.length, {
                    specializations: specializationList,
                })
            }}
        </template>
        <template v-else>
            {{
                $tc(translationKey('specializations'), specializations.length, {
                    specializations: specializationList,
                })
            }}<br>{{
                $tc(translationKey('locations'), locations.length, {
                    locations: locationList,
                })
            }}
        </template>
        <br>
        <template v-if="nameGln !== null && nameGln.length > 0">
            {{ $t('transactions.list.filters.name-gln', { nameGln }) }}
        </template>
    </small>
</template>

<script>
export default {
    name: 'TransactionFilterSummary',
    props: {
        nameGln: String,
        postalCodes: Array,
        localities: Array,
        cantons: Array,
        specializations: Array,
        practitionerType: String,
    },
    computed: {
        specializationList() {
            return this.specializations.map(s => s.title).join(', ');
        },
        locationList() {
            return this.locations.join(', ');
        },
        locations() {
            return [
                ...this.cantons.map(c => this.$t('transactions.list.filters.canton', {canton: c.name})),
                ...this.localities.map(l => l.name),
                ...this.postalCodes.map(p => p.code),
            ];
        }
    },
    methods: {
        translationKey(key) {
            return 'transactions.list.filters.' + key + '.' + (this.practitionerType ?? 'all');
        },
    }
}
</script>

<style scoped>
small.root {
    display: block;
}
</style>
