<template>
    <span class="text-dark" v-if="transaction.glnCount + transaction.inactiveGlnCount !== 0">
        (<template v-if="transaction.glnCount !== 0">{{
            $tc('transactions.list.gln-count', transaction.glnCount)
        }}<template v-if="transaction.downloadUrl !== null">
        &ndash;
        <subscribed-download-button tag="a" :user="user" :href="transaction.downloadUrl"
                                    :label="$t('transactions.list.actions.export')"
        /></template>
        <template v-if="transaction.inactiveGlnCount !== 0 || transaction.type === 'query'"
        >; </template></template>

        <template v-if="transaction.inactiveGlnCount !== 0">{{
                $tc('transactions.list.inactive-gln-count', transaction.inactiveGlnCount)
            }}<template v-if="transaction.downloadInactiveUrl !== null">
            &ndash;
            <subscribed-download-button tag="a" :user="user" :href="transaction.downloadInactiveUrl"
                                        :label="$t('transactions.list.actions.export')"
            />
        </template></template>
        <template v-else-if="transaction.type === 'query'">
            <router-link :to="{name: 'CustomerExport', query: {
                nameGln: transaction.nameGln,
                localities, postalCodes, cantons, specializations, practitionerType
            }}">{{
                    $t('transactions.list.actions.query-again')
                }}</router-link>
        </template>)
    </span>
</template>

<script>
import SubscribedDownloadButton from "@/components/conditionals/SubscribedDownloadButton";

export default {
    name: "TransactionLinkPanel",
    components: {SubscribedDownloadButton},
    props: {
        transaction: Object,
        user: Object,
    },
    computed: {
        localities() {
            return this.transaction.localities.map(locality => locality.name);
        },
        postalCodes() {
            return this.transaction.postalCodes.map(code => code.code);
        },
        cantons() {
            return this.transaction.cantons.map(canton => canton.symbol);
        },
        specializations() {
            return this.transaction.specializations.map(specialization => specialization.id);
        },
        practitionerType() {
            return this.transaction.practitionerType;
        },
    },
}
</script>
