<template>
    <b-container>
        <h1>{{ $t('admin.deposit.title') }}
            <router-link custom v-slot="{navigate}" v-if="currentUser != null"
                         :to="{name:'AdminUserTransactions',params:{user:currentUser.id}}"
            >
                <a class="btn btn-light float-right" @click="navigate" href="javascript:">
                    <b-icon icon="arrow-left-short"/>
                    {{ $t('admin.deposit.back-to-transactions') }}
                </a>
            </router-link>
        </h1>
        <b-alert :show="error" variant="danger">{{ $t('errors.unknown-error') }}</b-alert>
        <b-alert :show="!loading && currentUser.isAdmin" variant="warning">
            {{ $t('admin.deposit.error-is-admin') }}
        </b-alert>
        <deposit-form v-model="entity" v-bind:current-user="currentUser" :errors="errors"
                      v-if="!loading && !error && !currentUser.isAdmin"
                      @submit="createTransaction()"
                      show-account-type show-password
        >
            <submit-panel submit-label="admin.deposit.form.submit"/>
        </deposit-form>
        <loading-indicator v-model="loading"/>
    </b-container>
</template>

<script>
import User from "@/models/User";
import SubmitPanel from "@/components/SubmitPanel";
import DepositForm from "@/components/forms/DepositForm";
import Transaction from "@/models/Transaction";
import LoadingIndicator from "@/components/LoadingIndicator";
import {dateFormatter, defaultErrorHandler} from "@/helpers/utils";

export default {
    components: {LoadingIndicator, DepositForm, SubmitPanel},
    props: {
        user: User,
    },
    data() {
        return {
            entity: new Transaction({
                type: 'deposit',
                bookingText: this.$t('admin.deposit.booking-text', {date: dateFormatter(new Date())}),
            }),
            currentUser: null,
            errors: {},
            error: false,
            loading: true,
        };
    },
    created() {
        this.$axios.get('/api/admin/users/' + this.$route.params.user)
            .then((response) => {
                this.currentUser = new User(response.data.data);
                this.loading = false;
            })
            .catch(defaultErrorHandler(this));
    },
    methods: {
        createTransaction() {
            this.$axios.post('/api/admin/users/' + this.$route.params.user + '/transactions', this.entity)
                .then(() => this.$router.push({name: 'AdminUserTransactions', params: {user: this.$route.params.user}}))
                .catch(defaultErrorHandler(this));
        },
    },
}
</script>
