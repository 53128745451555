<template>
    <b-container>
        <h1>{{ $t('customer.acquisition.title') }}</h1>

        <p>{{ $t('customer.acquisition.instructions') }}</p>
        <acquisition-form @acquire="acquire($event)" v-if="!error"></acquisition-form>
        <b-alert :show="error" variant="danger">{{ $t('errors.unknown-error') }}</b-alert>
    </b-container>
</template>

<script>
import AcquisitionForm from "@/components/forms/AcquisitionForm";
import {UPDATE_TRANSACTION} from "@/store/mutation-types";
import {defaultErrorHandler} from "@/helpers/utils";

export default {
    components: {AcquisitionForm},
    data: function () {
        return {
            error: false,
        };
    },
    methods: {
        acquire(acquisition) {
            this.$axios.post('/api/customer/acquisition', acquisition)
                .then((res) => {
                    this.$store.commit(UPDATE_TRANSACTION, res.data.data);
                    this.$router.push('/acquisition/' + res.data.data.id);
                })
                .catch(defaultErrorHandler(this));
        },
    }
}
</script>
