<template>
    <b-container>
        <b-row>
            <b-col offset="0" cols="12"
                   offset-sm="2" sm="8"
                   offset-md="3" md="6"
                   offset-lg="3" lg="6"
                   offset-xl="3" xl="6">
                <b-card no-body>
                    <b-card-header class="logo-background pb-4 pt-4">
                        <img :alt="$t('app.vendor')" src="../assets/logo.png">
                    </b-card-header>
                    <b-card-body>
                        <b-card-title class="mb-4">{{ title }}</b-card-title>
                        <slot></slot>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "ChironDialog",
    props: {
        title: String,
    },
}
</script>

<style scoped lang="scss">
.container {
    margin-top: 60px;
}

.logo-background {
    background-color: $secondary;
    > img {
        width: 300px;
        max-width: 100%;
    }
}
</style>
