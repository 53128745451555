<template>
    <b-alert :show="!!errors[name]" variant="danger">
        <span v-for="error in errors[name]" :key="error">{{ error }}<br></span>
    </b-alert>
</template>

<script>
export default {
    name: "ErrorDisplay",
    props: {
        errors: Object,
        name: String,
    },
}
</script>
