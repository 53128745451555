<template>
    <b-navbar sticky toggleable="lg" type="dark" variant="secondary">
        <router-link class="navbar-brand" :to="{name:'EntryPage'}">{{$t('app.name') }}</router-link>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav v-if="!user.isAdmin">
                <template v-if="user.hasDoctorsImported">
                    <b-nav-item :to="{name:'CustomerExport'}" active-class="active">{{ $t('customer.export.title') }}</b-nav-item>
                    <b-nav-item :to="{name:'CustomerAcquisition'}" active-class="active">{{ $t('customer.acquisition.title') }}</b-nav-item>
                    <b-nav-item :to="{name:'CustomerSubsequentImport'}" active-class="active">{{ $t('customer.subsequent-import.title') }}</b-nav-item>
                    <b-nav-item :to="{name:'CustomerUpdate'}" active-class="active">{{ $t('customer.update.title') }}</b-nav-item>
                    <b-nav-item :to="{name:'CustomerTransactions'}" active-class="active">{{ $t('customer.transactions.title') }}</b-nav-item>
                    <b-nav-item :to="{name:'CustomerAnalysis'}" active-class="active">{{ $t('customer.analysis.title') }}</b-nav-item>
                </template>
            </b-navbar-nav>
            <b-navbar-nav v-else>
                <b-nav-item :to="{name:'AdminUsers'}" active-class="active">{{ $t('admin.users.title') }}</b-nav-item>
                <b-nav-item :to="{name:'AdminTransactions'}" active-class="active">{{
                        $t('admin.transactions.title')
                    }}
                </b-nav-item>
                <b-nav-item :to="{name:'AdminAnalysis'}" active-class="active">{{ $t('admin.analysis.title') }}</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item v-if="!user.isAdmin && !user.hasActiveSubscription()">
                    <b-icon-exclamation-triangle-fill id="subscriptionWarningTriangle" class="text-warning"/>
                    <subscription-inactive-popover target="subscriptionWarningTriangle" :user="user"/>
                </b-nav-item>

                <b-nav-item-dropdown right>
                    <!-- Using 'button-content' slot -->
                    <template #button-content>
                        <b-icon icon="person-fill"></b-icon>
                        {{ user.name }}
                    </template>
                    <b-dropdown-item :to="{name:'UserSettings'}">{{ $t('user.settings.title') }}</b-dropdown-item>
                    <b-dropdown-item>
                        <logout-form :user="user" v-on:logout="$emit('logout')"></logout-form>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import LogoutForm from "@/components/LogoutForm";
import SubscriptionInactivePopover from "@/components/popovers/SubscriptionInactivePopover";

export default {
    name: "NavBar",
    components: {SubscriptionInactivePopover, LogoutForm},
    props: {
        user: Object,
    },
}
</script>

<style scoped lang="scss">
.nav-link:focus {
    outline: none;
}
</style>
