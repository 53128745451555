<template>
    <b-form-group :label="$t('customer.export.store.label')">
        <b-form-input v-model="note" :placeholder="$t('customer.export.store.placeholder')"/>
    </b-form-group>
</template>

<script>
import {UPDATE_TRANSACTION} from "@/store/mutation-types";

export default {
    name: "StoreExportForm",
    props: {
        nameGln: String,
        localities: Array,
        postalCodes: Array,
        cantons: Array,
        specializations: Array,
        practitionerType: String,
    },
    data() {
        return {
            note: '',
        };
    },
    methods: {
        store() {
            if (this.note.length === 0) {
                return;
            }
            this.$emit('storing');
            this.$axios.post('/api/customer/export', {
                nameGln: this.nameGln,
                localities: this.localities,
                postalCodes: this.postalCodes,
                cantons: this.cantons,
                specializations: this.specializations,
                practitionerType: this.practitionerType,
                bookingText: this.note,
            })
                .then((res) => {
                    this.note = '';
                    this.$store.commit(UPDATE_TRANSACTION, res.data.data);
                    this.$emit('stored');
                })
                .catch(() => this.$emit('error'));
        },
    },
}
</script>
