<template>
    <b-container>
        <h1>
            {{ $t('admin.users.title') }}
            <router-link custom v-slot="{navigate}" :to="{name:'AdminCreateUser'}">
                <a class="btn btn-secondary float-right" @click="navigate" href="javascript:">
                    <b-icon icon="person-plus"/>
                    {{ $t('admin.users.create') }}
                </a>
            </router-link>
        </h1>
        <b-table v-if="!loading" striped :items="users" :fields="fields" primary-key="id" sort-by="name">
            <template #cell(actions)="u">
                    <span class="actions">
                        <router-link :to="{name: 'AdminEditUser', params: {user: u.item.id}}">
                            <b-icon icon="pencil"/>
                        </router-link>
                        <router-link v-if="!u.item.isAdmin"
                                     :to="{name: 'AdminUserTransactions', params: {user: u.item.id}}"
                        >
                            <b-icon icon="credit-card"/>
                        </router-link>
                    </span>
            </template>
        </b-table>
        <loading-indicator :value="loading"/>
    </b-container>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator";
import User from "@/models/User";
import {dateFormatter} from "@/helpers/utils";

export default {
    components: {LoadingIndicator},
    props: {
        user: User,
    },
    data() {
        return {
            loading: true,
            users: [],
            fields: [
                {
                    key: 'name',
                    label: this.$t('admin.users.entity.name'),
                    sortable: true,
                },
                {
                    key: 'email',
                    label: this.$t('admin.users.entity.email'),
                    sortable: true,
                },
                {
                    key: 'subscriptionActiveUntil',
                    label: this.$t('admin.users.entity.subscription-active-until'),
                    sortable: true,
                    formatter: dateFormatter,
                },
                {
                    key: 'actions',
                    label: this.$t('admin.users.entity.actions'),
                    sortable: false,
                },
            ],
        };
    },
    created() {
        // only load the user list once as it changes not that often
        this.$axios.get('/api/admin/users')
            .then((response) => {
                this.users = User.collection(response.data.data);
                this.loading = false;
            });
    },
}
</script>

<style scoped>
.actions .b-icon {
    margin-right: .5em;
}
</style>
