<template>
    <b-container>
        <h1>{{ $t('customer.initial-import.title') }}</h1>
        <p>{{ $t('customer.initial-import.intro') }}</p>
        <p>{{ $t('customer.initial-import.instructions') }}</p>

        <import-accept-form v-if="importResult === null"
                            :error="error" :error-message="errorMessage" :upload-progress="uploadProgress"
                            @file="upload" @reset="reset"
        />
        <initial-import-success-panel v-else :import-result="importResult"/>
    </b-container>
</template>

<script>
import {SET_USER} from "@/store/mutation-types";
import ImportAcceptForm from "@/components/import/ImportAcceptForm.vue";
import InitialImportSuccessPanel from "@/components/import/InitialImportSuccessPanel.vue";

export default {
    components: {InitialImportSuccessPanel, ImportAcceptForm},
    props: {
        user: Object,
    },
    data() {
        return {
            uploadProgress: 0,
            error: false,
            errorMessage: null,
            file: null,
            apiUrl: '/api/customer/import/initial/upload',
            importResult: null,
        };
    },
    methods: {
        setDoctorsImported(importResult) {
            var user = this.user;
            user.hasDoctorsImported = true;
            this.$store.commit(SET_USER, user);
            this.importResult = importResult;
        },
        reset() {
            this.error = false;
            this.uploadProgress = 0;
        },
        upload(file) {
            this.file = file;

            const data = new FormData();
            data.append('file', this.file);
            this.$axios.post(this.apiUrl, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    this.uploadProgress = Math.round(progressEvent.loaded / progressEvent.total)
                },
            })
                .then((response) => this.setDoctorsImported(response.data.data))
                .catch((response) => {
                    this.error = true
                    this.errorMessage = response.response.data.message ?? null;
                });
        },
    },
}
</script>
