<template>
    <b-form @submit.prevent="$emit('acquire', {nameGln, localities, postalCodes, cantons, specializations, practitionerType, note})">
        <b-row>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('customer.export.filter.label.name-gln')">
                    <b-input type="text" v-model="nameGln" debounce="500"
                             :placeholder="$t('customer.export.filter.placeholder.name-gln')"
                    />
                </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
                <region-chooser :selected-cantons="cantons" @input:selectedCantons="cantons = $event"
                                :selected-localities="localities" @input:selectedLocalities="localities = $event"
                                :selected-postal-codes="postalCodes" @input:selectedPostalCodes="postalCodes = $event"
                ></region-chooser>
            </b-col>

            <b-col cols="12" md="6">
                <practitioner-type-chooser v-model="practitionerType"></practitioner-type-chooser>
            </b-col>

            <b-col cols="12" md="6">
                <specialization-chooser v-model="specializations"></specialization-chooser>
            </b-col>
        </b-row>

        <b-alert variant="success" :show="true">
            {{ $tc('customer.acquisition.offer', this.offer.count, {price: this.offer.price}) }}
        </b-alert>

        <b-btn variant="primary" :disabled="this.offer.count === 0 || this.offer.price > this.offer.balance" type="submit">
            {{ $tc('customer.acquisition.action.buy', this.offer.count) }}
        </b-btn>
    </b-form>
</template>

<script>
import SpecializationChooser from "@/components/inputs/SpecializationChooser";
import PractitionerTypeChooser from "@/components/inputs/PractitionerTypeChooser";
import RegionChooser from "@/components/inputs/RegionChooser.vue";

export default {
    components: {SpecializationChooser, RegionChooser, PractitionerTypeChooser},
    data() {
        return {
            nameGln: '',
            localities: [],
            postalCodes: [],
            cantons: [],
            specializations: [],
            practitionerType: null,
            note: null,
            offer: {
                count: 0,
                price: 0,
                balance: 0,
            },
        };
    },
    created() {
        if(this.$route.query.nameGln !== undefined) {
            this.nameGln = this.$route.query.nameGln;
        }
        if(this.$route.query.localities !== undefined) {
            this.localities = this.$route.query.localities;
        }
        if(this.$route.query.postalCodes !== undefined) {
            this.postalCodes = this.$route.query.postalCodes;
        }
        if(this.$route.query.cantons !== undefined) {
            this.cantons = this.$route.query.cantons;
        }
        if(this.$route.query.specializations !== undefined) {
            this.specializations = this.$route.query.specializations;
        }
        if(this.$route.query.practitionerType !== undefined) {
            this.practitionerType = this.$route.query.practitionerType;
        }
        this.refreshOffer();
    },
    methods: {
        refreshOffer() {
            this.$axios.get('/api/customer/acquisition/offer', {
                params: {
                    nameGln: this.nameGln,
                    localities: this.localities,
                    postalCodes: this.postalCodes,
                    cantons: this.cantons,
                    specializations: this.specializations,
                    practitionerType: this.practitionerType,
                }
            })
                .then((res) => this.offer = res.data);
        },
    },
    watch: {
        nameGln: 'refreshOffer',
        localities: 'refreshOffer',
        postalCodes: 'refreshOffer',
        cantons: 'refreshOffer',
        specializations: 'refreshOffer',
        practitionerType: 'refreshOffer',
    },
}
</script>
