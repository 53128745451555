<template>
    <b-container>
        <h1>{{ $t('customer.subsequent-import.title') }}</h1>
        <p>{{ $t('customer.subsequent-import.intro') }}</p>
        <p>{{ $t('customer.subsequent-import.instructions') }}</p>

        <import-accept-form v-if="analysisResult === null || error"
                            :error="error" :error-message="errorMessage" :upload-progress="uploadProgress"
                            @file="analyze" @reset="reset"
        />
        <analysis-result v-else-if="importResult === null" :analysis-result="analysisResult"
                         @reset="reset" @import="doImport"
        />
        <subsequent-import-success-panel v-else :user="user" :import-result="importResult"/>
    </b-container>
</template>

<script>
import ImportAcceptForm from "@/components/import/ImportAcceptForm.vue";
import AnalysisResult from "@/components/import/AnalysisResult.vue";
import SubsequentImportSuccessPanel from "@/components/import/SubsequentImportSuccessPanel.vue";

export default {
    components: {SubsequentImportSuccessPanel, AnalysisResult, ImportAcceptForm},
    props: {
        user: Object,
    },
    data() {
        return {
            error: false,
            errorMessage: null,
            uploadProgress: 0,
            file: null,
            apiUrlAnalyze: '/api/customer/import/subsequent/analyze',
            analysisResult: null,
            apiUrlImport: '/api/customer/import/subsequent/upload',
            importResult: null,
        };
    },
    methods: {
        reset() {
            this.error = false;
            this.uploadProgress = 0;
            this.analysisResult = null;
        },
        analyze(file) {
            this.file = file;

            const data = new FormData();
            data.append('file', this.file);
            this.$axios.post(this.apiUrlAnalyze, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    this.uploadProgress = Math.round(progressEvent.loaded / progressEvent.total)
                },
            })
                .then((response) => this.analysisResult = response.data.data)
                .catch((response) => {
                    this.error = true
                    this.errorMessage = response.response.data.message ?? null;
                });
        },
        doImport() {
            const data = new FormData();
            data.append('file', this.file);
            this.$axios.post(this.apiUrlImport, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then((response) => this.importResult = response.data.data)
                .catch((response) => {
                    this.error = true
                    this.errorMessage = response.response.data.message ?? null;
                });
        },
    },
}
</script>
