<template>
    <a @click="logout">{{ $t('auth.logout.submit') }}</a>
</template>

<script>
import {PURGE} from "@/store/mutation-types";

export default {
    name: "LogoutForm",
    props: {
        value: Object,
    },
    methods: {
        logout: function () {
            this.$axios.post('/api/auth/logout')
                .then(() => {
                    this.$store.commit(PURGE);
                    this.$router.push({name: 'EntryPage'});
                });
        },
    },
}
</script>

<style scoped>
a {
    display: block;
}
</style>
