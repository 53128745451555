<template>
    <div class="inline-loading-indicator">
        <transition name="fade" on:enter="enter">
            <span v-if="loading" class="text-secondary">
                <b-icon icon="arrow-repeat" animation="spin"/>
                {{ labelLoading }}
            </span>
        </transition>
        <transition name="fade" on:enter="enter">
            <span v-if="confirming" class="text-success">
                <b-icon icon="check"/>
                {{ labelConfirming }}
            </span>
        </transition>
        <transition name="fade" on:enter="enter">
            <span v-if="error" class="text-danger">
                <b-icon icon="x"/>
                {{ labelError }}
            </span>
        </transition>
    </div>
</template>

<script>
export default {
    name: "InlineLoadingIndicator",
    props: {
        autoHideConfirmTimeout: {type: Number, default: 1000},
        labelLoading: String,
        labelConfirming: String,
        labelError: String,
    },
    data() {
        return {
            loading: false,
            confirming: false,
            error: false,
        };
    },
    methods: {
        reset() {
            this.loading = false;
            this.confirming = false;
            this.error = false;
        },
        startLoading() {
            this.reset();
            this.loading = true;
        },
        startConfirming() {
            this.reset();
            this.confirming = true;
            if (this.autoHideConfirmTimeout !== 0) {
                setTimeout(this.reset, this.autoHideConfirmTimeout);
            }
        },
        showError() {
            this.reset();
            this.error = true;
        },
    },
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.inline-loading-indicator {
    position: relative;

    > span {
        position: absolute;
    }
}
</style>
