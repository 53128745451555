import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'

Vue.config.productionTip = false

Vue.directive('click-outside', {
    bind: function (element, binding, vnode) {
        element.clickOutsideEvent = function (event) {
            // check if click was outside the element
            if (!(element === event.target || element.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', element.clickOutsideEvent, true)
    },
    unbind: function (element) {
        document.body.removeEventListener('click', element.clickOutsideEvent, true)
    },
})

new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')
