<template>
    <p><b>{{ $t('transactions.list.current-balance-title') }}</b>
        {{ $t('transactions.list.current-balance', {balance}) }}</p>
</template>
<script>
export default {
    name: 'ComputedBalance',
    props: {
        transactions: Array,
    },
    computed: {
        balance() {
            let sum = 0;
            for (let i = 0; i < this.transactions.length; i++) {
                sum += this.transactions[i].credits;
            }
            return sum;
        },
    },
}
</script>
