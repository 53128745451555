<template>
    <b-container>
        <h1>Alle Transaktionen</h1>

        <transaction-list v-if="!loading" :transactions="transactions" :show-user="true" :user="user"/>
        <loading-indicator :value="loading"/>
    </b-container>
</template>

<script>
import Transaction from "@/models/Transaction";
import TransactionList from "@/components/transactions/TransactionList";
import LoadingIndicator from "@/components/LoadingIndicator";

export default {
    components: {LoadingIndicator, TransactionList},
    props: {
        user: Object,
    },
    data() {
        return {
            loading: true,
            transactions: null,
        };
    },
    created() {
        // only load the user list once as it changes not that often
        this.$axios.get('/api/admin/transactions')
            .then((response) => {
                this.transactions = Transaction.collection(response.data.data);
                this.loading = false;
            });
    },
}
</script>
