<template>
    <div>
        <b-alert variant="success" show>
            <b-icon icon="check2"/>
            {{ $t('customer.import.success.message', {num: importResult.numImported}) }}
        </b-alert>
        <b-alert show v-if="importResult.numInvalidRecords">
            <b-icon icon="info-circle"/>
            {{ $tc('customer.import.analysis.hints.non-glns', importResult.numInvalidRecords) }}<br>
        </b-alert>
        <b-alert show v-if="importResult.invalidGlns && importResult.invalidGlns.length > 0" variant="warning">
            <b-icon icon="info-circle"/>
            {{
                $tc('customer.import.analysis.hints.invalid',
                    importResult.invalidGlns.length,
                    {gln: importResult.invalidGlns.join(', ')})
            }}<br>
        </b-alert>
        <b-alert show v-if="importResult.numDuplicates" variant="warning">
            <b-icon icon="info-circle"/>
            {{ $tc('customer.import.analysis.hints.duplicates', importResult.numDuplicates) }}
            <a :href="importResult.duplicatesUrl">{{ $t('customer.import.success.download-duplicates') }}</a>
        </b-alert>
        <b-alert show v-if="importResult.numInactive">
            <b-icon icon="info-circle"/>
            {{ $tc('customer.import.analysis.hints.after-import.inactive', importResult.numInactive) }}<br>
        </b-alert>
        <b-alert show v-if="importResult.numInstitutions">
            <b-icon icon="info-circle"/>
            {{ $tc('customer.import.analysis.hints.institutions', importResult.numInstitutions) }}<br>
        </b-alert>
        <b-alert show v-if="importResult.numUnknown">
            <b-icon icon="info-circle"/>
            {{ $tc('customer.import.analysis.hints.after-import.unknown', importResult.numUnknown) }}<br>
        </b-alert>
        <router-link class="btn btn-primary" :to="{name: 'CustomerDashboard'}">
            {{ $t('customer.import.analysis.continue') }}
        </router-link>
    </div>
</template>

<script>
export default {
    name: "InitialImportSuccessPanel",
    props: {
        importResult: {
            type: Object,
        },
    },
}
</script>
