<template>
    <div>
        <b-alert variant="info" :show="transactions.length === 0">
            {{ $t('transactions.list.no-transactions') }}
        </b-alert>

        <template v-if="transactions.length > 0">
            <b-table striped :items="transactions" :fields="fields" primary-key="id" sort-by="date" sort-desc>
                <template v-slot:cell(bookingText)="data">
                    {{ data.item.bookingText }}

                    <transaction-link-panel :transaction="data.item" :user="user"/>

                    <transaction-filter-summary v-if="'specializations' in data.item && 'cantons' in data.item"
                                                :specializations="data.item.specializations"
                                                :name-gln="data.item.nameGln"
                                                :localities="data.item.localities"
                                                :postal-codes="data.item.postalCodes"
                                                :cantons="data.item.cantons"
                                                :practitioner-type="data.item.practitionerType"
                    />
                </template>

                <template v-slot:cell(user)="data">{{ data.value.name }}</template>

                <template v-slot:cell(credits)="data">
                    <div class="text-right">{{ data.item.credits }}</div>
                </template>
            </b-table>
        </template>
    </div>
</template>

<script>
import {dateTimeFormatter} from "@/helpers/utils";
import TransactionFilterSummary from "@/components/transactions/TransactionFilterSummary";
import TransactionLinkPanel from "@/components/transactions/TransactionLinkPanel";

export default {
    name: "TransactionList",
    components: {TransactionLinkPanel, TransactionFilterSummary},
    props: {
        transactions: Array,
        showUser: {
            type: Boolean,
            default: false,
        },
        user: Object,
    },
    data() {
        return {
            fields: [
                {
                    key: 'date',
                    label: this.$t('transactions.list.entity.date'),
                    sortable: true,
                    formatter: dateTimeFormatter,
                },
                ...(this.showUser ? [{
                    key: 'user',
                    label: this.$t('transactions.list.entity.user'),
                    sortable: true,
                }] : []),
                {
                    key: 'type',
                    label: this.$t('transactions.list.entity.type'),
                    sortable: true,
                    formatter: (value) => this.$t('transactions.types')[value] ?? value,
                },
                {
                    key: 'bookingText',
                    label: this.$t('transactions.list.entity.booking-text'),
                    sortable: true,
                },
                {
                    key: 'credits',
                    label: this.$t('transactions.list.entity.credits'),
                    sortable: true,
                },
            ],
        }
    }
}
</script>
