<template>
    <b-container>
        <h1>{{ $t('customer.export.title') }}</h1>
        <p>{{ $t('customer.export.instructions') }}</p>

        <b-row>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('customer.export.filter.label.name-gln')">
                    <b-input type="text" v-model="nameGln" debounce="500"
                             :placeholder="$t('customer.export.filter.placeholder.name-gln')"
                    />
                </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
                <region-chooser :selected-cantons="cantons" @input:selectedCantons="cantons = $event"
                                :selected-localities="localities" @input:selectedLocalities="localities = $event"
                                :selected-postal-codes="postalCodes" @input:selectedPostalCodes="postalCodes = $event"
                ></region-chooser>
            </b-col>

            <b-col cols="12" md="6">
                <practitioner-type-chooser v-model="practitionerType"></practitioner-type-chooser>
            </b-col>

            <b-col cols="12" md="6">
                <specialization-chooser v-model="specializations"></specialization-chooser>
            </b-col>
        </b-row>

        <b-alert variant="success" :show="true">
            {{ $tc('customer.export.counts.accessible', this.counts.accessible) }}
        </b-alert>
        <b-alert :show="this.counts.available > 0 || this.counts.accessible === 0">
            {{ $tc('customer.export.counts.available', this.counts.available) }}
            <router-link v-if="this.counts.available > 0"
                         :to="{name: 'CustomerAcquisition',
                         query: {nameGln, localities, postalCodes, cantons, specializations, practitionerType}}"
            >{{ $t('customer.export.action.buy-more') }}
            </router-link>
        </b-alert>

        <store-export-form :name-gln="nameGln"
                           :localities="localities"
                           :postal-codes="postalCodes"
                           :cantons="cantons"
                           :specializations="specializations"
                           :practitioner-type="practitionerType"
                           @storing="$refs.loadingIndicator.startLoading()"
                           @stored="$refs.loadingIndicator.startConfirming()"
                           @error="$refs.loadingIndicator.showError()"
                           ref="storeExport"
        />

        <b-row>
            <b-col cols="auto">
                <subscribed-download-button variant="primary" :user="user" @click="download()"
                                            :disabled="this.counts.accessible === 0"
                                            :label="$tc('customer.export.action.export', this.counts.accessible)"
                />
            </b-col>
            <b-col>
                <inline-loading-indicator ref="loadingIndicator" class="mt-2"
                                          :label-loading="$t('customer.export.indicator.storing')"
                                          :label-confirming="$t('customer.export.indicator.stored')"
                                          :label-error="$t('customer.export.indicator.error')"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import SpecializationChooser from "@/components/inputs/SpecializationChooser";
import PractitionerTypeChooser from "@/components/inputs/PractitionerTypeChooser";
import {navigate} from "@/helpers/utils";
import SubscribedDownloadButton from "@/components/conditionals/SubscribedDownloadButton";
import StoreExportForm from "@/components/forms/StoreExportForm";
import InlineLoadingIndicator from "@/components/InlineLoadingIndicator";
import RegionChooser from "@/components/inputs/RegionChooser.vue";

export default {
    components: {
        RegionChooser,
        InlineLoadingIndicator,
        StoreExportForm,
        SubscribedDownloadButton,
        SpecializationChooser,
        PractitionerTypeChooser,
    },
    props: {
        user: Object,
    },
    created() {
        if (this.$route.query.nameGln !== undefined) {
            this.nameGln = this.$route.query.nameGln;
        }
        if (this.$route.query.localities !== undefined) {
            if (Array.isArray(this.$route.query.localities)) {
                this.localities = this.$route.query.localities;
            } else {
                this.localities = [this.$route.query.localities];
            }
        }
        if (this.$route.query.postalCodes !== undefined) {
            if (Array.isArray(this.$route.query.postalCodes)) {
                this.postalCodes = this.$route.query.postalCodes;
            } else {
                this.cantons = [this.$route.query.postalCodes];
            }
        }
        if (this.$route.query.cantons !== undefined) {
            if (Array.isArray(this.$route.query.cantons)) {
                this.cantons = this.$route.query.cantons;
            } else {
                this.cantons = [this.$route.query.cantons];
            }
        }
        if (this.$route.query.specializations !== undefined) {
            if (Array.isArray(this.$route.query.specializations)) {
                this.specializations = this.$route.query.specializations;
            } else {
                this.specializations = [this.$route.query.specializations];
            }
        }
        if (this.$route.query.practitionerType !== undefined) {
            this.practitionerType = this.$route.query.practitionerType;
        }
        this.refreshCounts();
    },
    data() {
        return {
            nameGln: '',
            localities: [],
            postalCodes: [],
            cantons: [],
            specializations: [],
            practitionerType: null,
            counts: {
                available: 0,
                accessible: 0,
            },
        };
    },
    methods: {
        refreshCounts() {
            this.$axios.get('/api/customer/export/count', {
                params: {
                    nameGln: this.nameGln,
                    localities: this.localities,
                    postalCodes: this.postalCodes,
                    cantons: this.cantons,
                    specializations: this.specializations,
                    practitionerType: this.practitionerType,
                }
            })
                .then((res) => this.counts = res.data);
        },
        download() {
            this.$refs.storeExport.store();
            navigate('/api/customer/export/download', {
                nameGln: this.nameGln,
                localities: this.localities,
                postalCodes: this.postalCodes,
                cantons: this.cantons,
                specializations: this.specializations,
                practitionerType: this.practitionerType,
            });
        },
    },
    watch: {
        nameGln: 'refreshCounts',
        localities: 'refreshCounts',
        postalCodes: 'refreshCounts',
        cantons: 'refreshCounts',
        specializations: 'refreshCounts',
        practitionerType: 'refreshCounts',
    },
}
</script>
